.default-style .sidenav .app-brand.demo {
    height: 66px;
}

.material-style .sidenav .app-brand.demo {
    height: 70px;
}

.app-brand-logo.demo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 26px;
    height: 25px;
}

.app-brand-logo.demo svg {
    width: 26px;
    height: 25px;
}

.app-brand-text.demo {
    font-size: 0.75rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
    display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
    display: block !important;
}

@media (min-width: 992px) {
    .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
    .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
        display: none;
    }
}

/* *****************************************************************************
 * Navbar
 */

.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
    white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
    overflow: hidden;
    padding: 0;
}

@media (min-width: 992px) {
    .demo-navbar-messages .dropdown-menu,
    .demo-navbar-notifications .dropdown-menu {
        margin-top: .5rem;
        width: 22rem;
    }

    .demo-navbar-user .dropdown-menu {
        margin-top: .25rem;
    }
}

.breadcrumb a:hover {
    text-decoration: underline;
}

.chit-view-info a {
    color: #4E5155;
}

.chit-view-info a:hover {
    text-decoration: underline;
}
